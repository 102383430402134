import Siema from 'siema'
import Accordion from 'accordion-js'
import Overlay from './overlay'
import Cookieconssent from './cookieconsent'

(function () {
  (document.readyState !== 'loading')
    ? main()
    : document.addEventListener('DOMContentLoaded',
      function () {
        main()
      })
})()


const preloadImage = function (element) {
  const src = element.dataset.src
  const srcset = element.dataset.srcset
  if (src) {
    element.setAttribute('src', src)
  }
  if (srcset) {
    element.setAttribute('srcset', srcset)
  }
}

function main() {
  // JS-CSS-Klasse anfügen
  document.documentElement.className = document.documentElement.className.replace('no-js', 'js')

  // Lazy load images
  const observer = new IntersectionObserver(function (entries, self) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        preloadImage(entry.target)
        self.unobserve(entry.target)
      }
    })
  }, {
    rootMargin: '0px 0px 50px 0px',
    threshold: 0
  })

  const imgs = document.querySelectorAll('[data-src]')
  imgs.forEach(img => {
    observer.observe(img)
  })

  // Menu-Toggle
  const toggle = document.getElementById('menutoggle')
  if (toggle) {
    toggle.addEventListener('click', function (event) {
      this.classList.toggle('is-active')
      this.parentElement.classList.toggle('-expanded')
    })
  }

  // Info-Slider
  const infoSliders = document.getElementsByClassName('module-infoslider')
  if (infoSliders.length > 0) {
    for (let infoSlider of infoSliders) {
      let infoSliderInterval

      const container = infoSlider.querySelector('.module-infoslider--container')
      const prevButton = infoSlider.querySelector('.module-infoslider--trigger-prev')
      const nextButton = infoSlider.querySelector('.module-infoslider--trigger-next')
      const indicators = infoSlider.querySelector('.module-infoslider--indicators')
      const duration = infoSlider.dataset.duration

      const siema = new Siema({
        selector: container,
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 0,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: true,
        onInit: () => {
          if (indicators) {
            indicators.querySelector('li').classList.add('-active')
          }
          infoSliderInterval = setInterval(() => {
            siema.next()
          }, duration)
        },
        onChange: () => {
          if (indicators) {
            for (let i = 0; i < indicators.querySelectorAll('li').length; i++) {
              if (i == siema.currentSlide) {
                indicators.querySelectorAll('li')[i].classList.add('-active')
              } else {
                indicators.querySelectorAll('li')[i].classList.remove('-active')
              }
            }
          }
        },
      });

      if (prevButton) {
        prevButton.addEventListener('click', () => {
          siema.prev()
          clearInterval(infoSliderInterval)
        })
      }

      if (prevButton) {
        nextButton.addEventListener('click', () => {
          siema.next()
          clearInterval(infoSliderInterval)
        })
      }
    }
  }

  // Partner-Slider
  document.querySelectorAll('.module-partnerslider--inner').forEach(slider => {
    let infoSliderInterval

    const siema = new Siema({
      selector: slider,
      duration: 300,
      easing: 'ease-out',
      perPage: {
        0: 3,
        600: 4,
        1200: 5
      },
      startIndex: 0,
      draggable: true,
      multipleDrag: true,
      threshold: 20,
      loop: true,
      onInit: () => {
        infoSliderInterval = setInterval(() => {
          siema.next(1)
        }, 2850 + Math.floor(Math.random() * 300))
      },
      onChange: () => {
      }
    })
  })

  // Accordion
  const accordions = Array.from(document.querySelectorAll('.module-accordion'));
  new Accordion(accordions, {
    elementClass: 'module-accordion--item',
    triggerClass: 'module-accordion--header',
    panelClass: 'module-accordion--content',
    activeClass: '-expanded'
  });

  // YouTube Videos erst nach Einwilligung
  const youtubes = Array.from(document.querySelectorAll('.module-youtube'));
  youtubes.forEach(el => {
    el.addEventListener('click', event => {
      const cl = el.classList
      const id = el.dataset.youtubeid
      const html = `<iframe width="640" height="360" src="https://www.youtube.com/embed/${id}?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
      if (window.matchMedia("(hover: none)").matches) {
        if (cl.contains('-hover')) {
          el.innerHTML = html
        } else {
          cl.add('-hover')
        }
      } else {
        el.innerHTML = html
      }
    })

    el.addEventListener('mouseenter', event => {
      if (!window.matchMedia("(hover: none)").matches) {
        el.classList.add('-hover')
      }
    })

    el.addEventListener('mouseleave', event => {
      if (!window.matchMedia("(hover: none)").matches) {
        el.classList.remove('-hover')
      }
    })
  })

  // Introvideo
  const introVideoContainer = document.getElementsByClassName('module-introvideo')

  if (introVideoContainer.length > 0) {
    const introVideo = introVideoContainer[0].getElementsByTagName('video')[0]

    const webmElement =  document.createElement('source')
    webmElement.setAttribute('src', introVideo.dataset.webm)
    webmElement.setAttribute('type', 'video/webm');

    const mp4Element =  document.createElement('source')
    mp4Element.setAttribute('src', introVideo.dataset.mp4)
    mp4Element.setAttribute('type', 'video/mp4');

    introVideo.appendChild(webmElement)
    introVideo.appendChild(mp4Element)

    introVideo.addEventListener('ended', event => {
      setTimeout(() => {
        introVideoContainer[0].classList.add('-collapsed')
      }, 3000)
    })
  }

  // Sidemenu
  new Overlay()
  new Cookieconssent()
}
