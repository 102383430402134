export default class Cookieconsent {

  constructor() {
    this.banner = document.querySelector('.layout-cookie-consent')
    this.isConsent = document.cookie
      .split('; ')
      .find((row) => row.startsWith('consent='))
      ?.split('=')[1] === '1'
    const consentButton = this.banner.querySelector('.wp-block-button__link')
    if (! this.isConsent) {
      this.banner.classList.add('-visible')
      consentButton.addEventListener('click', event => this.handleClick(event))
    }
  }

  gentleHideBanner() {
    this.banner.style.bottom = `-${this.banner.clientHeight}px`
  }

  handleClick(event) {
    this.gentleHideBanner()
    if (! this.isConsent) {
      const day = 24*60*60
      const max_age = day * 10

      document.cookie = `consent=1; path=/; max-age=34560000; SameSite=Strict`

      this.gentleHideBanner()
    }
    event.preventDefault()
    return false
  }
}
