export default class Overlay {

  constructor() {
    const spinner = document.querySelector('.layout-spinner')
    this.spinnerCssClass = spinner.classList

    const overlay = document.querySelector('.layout-overlay')
    this.overlayCssClass = overlay.classList

    this.pagePanelCssClass = overlay.querySelector('.module-pagepanel').classList
    this.searchPanelCssClass = overlay.querySelector('.module-searchpanel').classList
    this.sideMenuLinks = document.querySelectorAll('.layout-sidemenu li.popup a');

    this.sideMenuLinks.forEach(link => {
      if (link.href.indexOf('#suche') > -1) {
        link.addEventListener('click', event => {
          this.hideOverlay()
          this.showSearchPanel()
          this.deselectMenuItems()
          event.target.classList.add('-selected')
          event.preventDefault()
          return false
        })
      } else {
        link.addEventListener('click', event => {
          this.hideOverlay()
          this.spinnerCssClass.add('-visible')
          fetch(link.href)
              .then((response) => response.text())
              .then((data) => {
                this.spinnerCssClass.remove('-visible')
                this.showPagePanel(data)
              })
          this.deselectMenuItems()
          event.target.classList.add('-selected')
          event.preventDefault()
          return false
        })
      }
    })

    document.querySelectorAll('.layout-overlay--close svg').forEach(closeButton => {
      closeButton.addEventListener('click', () => {
        this.pagePanelCssClass.remove('-visible')
        this.searchPanelCssClass.remove('-visible')
        this.deselectMenuItems()
        this.hideOverlay()
      })
    })
  }

  showOverlay() {
    this.overlayCssClass.add('-visible')
  }

  hideOverlay() {
    this.overlayCssClass.remove('-visible')
  }

  deselectMenuItems() {
    this.sideMenuLinks.forEach(link => {
      link.classList.remove('-selected')
    })
  }

  showPagePanel(content) {
    this.showOverlay()
    this.pagePanelCssClass.add('-visible')
    this.searchPanelCssClass.remove('-visible')
    document.querySelector('.module-pagepanel--content').innerHTML = content
  }

  showSearchPanel() {
    this.showOverlay()
    this.searchPanelCssClass.add('-visible')
    this.pagePanelCssClass.remove('-visible')
  }
}
